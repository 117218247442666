import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: 'logoo',
            text: 'Home',
            link: '/dashboard/'
        },
        {
            text: 'Users',
            link: '#',
            items: [
                {
                    text: 'Add Staff',
                    link: '/add-staff/'
                },
                {
                    text: 'View Users',
                    link: '/view-users/'
                },
                {
                    text: 'View Staff',
                    link: '/view-staff/'
                },
                {
                    text: 'Banned Users',
                    link: '/banned-users/'
                },
                {
                    text: 'Pending Approval',
                    link: '/account/list-pending-approval/'
                }
                /* {
                    text: 'Rejected Users',
                    link: '/rejected-users/'
                },
                {
                    text: 'Plans for Users',
                    link: '/plans-for-users/'
                } */
            ]
        },
        {
            icon: '',
            text: 'Directory',
            link: '#',
            items: [
                {
                    text: 'Add Category',
                    link: '/add-category/'
                }
                // {
                //     text: 'Add Batch',
                //     link: '/add-batch/'
                // },
                // {
                //     text: 'Add Contact',
                //     link: '/add-contact/'
                // },
                // {
                //     text: 'Add Contacts all',
                //     link: '/add-contact-all/'
                // },
                // {
                //     text: 'Add Dept Animal Husbandary',
                //     link: '/add-department-animal-husbandary/'
                // },
                // {
                //     text: 'Add Farm',
                //     link: '/add-farm/'
                // },
                // {
                //     text: 'Add University Office',
                //     link: '/add-university-office/'
                // },
                // {
                //     text: 'Add Vet Doc Kerala',
                //     link: '/add-vet-doc-kerala/'
                // }
            ]
        },
        {
            icon: '',
            text: 'Drug Index',
            link: '#',
            items: [
                {
                    text: 'Add Drugs',
                    link: '/drug-index/add-drugs/'
                },
                {
                    text: 'View Drugs',
                    link: '/view-drugs/'
                },
                {
                    text: 'Add Category',
                    link: '/drug-index/add-category/'
                },
                {
                    text: 'View Category',
                    link: '/view-category/'
                },
                {
                    text: 'Add Brands',
                    link: '/drug-index/add-brands/'
                },
                {
                    text: 'View Brands',
                    link: '/view-brands/'
                },
                {
                    text: 'Excel Download Brands',
                    event: 'brand-excel-download',
                    link: '#'
                },
                {
                    text: 'Recommended Brands list',
                    link: '/recommended-brands/'
                },
                {
                    text: 'Issues Reported',
                    link: '/issue-reported/'
                }
            ]
        },
        {
            icon: '',
            text: 'Advertisement',
            link: '#',
            items: [
                {
                    text: 'Add',
                    link: '/add-advertisement/'
                },
                {
                    text: 'List',
                    link: '/advertisement-list/'
                }
            ]
        },
        {
            icon: '',
            text: 'Articles',
            link: '#',
            items: [
                {
                    text: 'Add Article',
                    link: '/add-article/'
                },
                {
                    text: 'Article List',
                    link: '/articles-old-list/'
                }
                // {
                //     text: 'Add Article',
                //     link: '/add-article/'
                // },
                // {
                //     text: 'Article List',
                //     link: '/articles-list/'
                // }
            ]
        },
        {
            icon: '',
            text: 'Plans',
            link: '#',
            items: [
                {
                    text: 'Add Plans',
                    link: '/add-plans/'
                },
                {
                    text: 'Active Plans',
                    link: '/active-plans/'
                },
                {
                    text: 'Former Plans',
                    link: '/former-plans/'
                }
            ]
        },
        {
            icon: '',
            text: 'Flash',
            link: '/flash/'
        },
        {
            icon: '',
            text: 'Prioritizing',
            link: '/prioritizing/'
        },
        {
            icon: '',
            text: 'Transactions',
            link: '#',
            items: [
                {
                    text: 'Transactions',
                    link: '/transactions/'
                },
                {
                    text: 'Refund',
                    link: '/refund/'
                }
            ]
        },
        {
            icon: '',
            text: 'Premium Contents',
            link: '#',
            items: [
                {
                    icon: '',
                    text: 'Diseases',
                    link: '#',
                    items: [
                        {
                            text: 'Species',
                            link: '/diseases-species/'
                        },
                        {
                            text: 'Add Disease',
                            link: '/add-disease/'
                        },
                        {
                            text: 'Diseases List',
                            link: '/diseases-list/'
                        },
                        /* {
                            text: 'Treatments List',
                            link: '/treatments-list/'
                        }, */
                        {
                            text: 'Users Favourites',
                            link: '/user-favourites/'
                        },
                        {
                            text: 'Disease Requests',
                            link: '/disease-requests/'
                        },
                        {
                            text: 'Diseases Issues Reported',
                            link: '/disease-issues-reported/'
                        }
                    ]
                },
                {
                    icon: '',
                    text: 'Diagnosis',
                    link: '#',
                    items: [
                        {
                            text: 'Normal Values',
                            link: '/normal-values/'
                        },
                        {
                            text: 'Specific Tests',
                            link: '/specific-tests/'
                        },
                        {
                            text: 'Clinical Signs',
                            link: '/clinical-signs/'
                        },
                        {
                            text: 'Auscultations/Palpation',
                            link: '/auscultations/'
                        },
                        {
                            text: 'Complete Blood Count',
                            link: '/complete-blood-count/'
                        },
                        {
                            text: 'Blood Chemistry',
                            link: '/blood-chemistry/'
                        },
                        {
                            text: 'Urine Analysis',
                            link: '/urine-analysis/'
                        },
                        {
                            text: 'Pathological Findings',
                            link: '/pathological-findings/'
                        }
                    ]
                },
                {
                    icon: '',
                    text: 'Pharmacy',
                    link: '#',
                    items: [
                        {
                            text: 'Preperations',
                            link: '/preperations/'
                        },
                        {
                            text: 'Preperations Issues Reported',
                            link: '/preparations-issues-reported/'
                        },
                        {
                            text: 'Indications',
                            link: '/indications/'
                        }
                        /* {
                            text: 'Indications Issues Reported',
                            link: '/indications-issue-reported/'
                        } */
                    ]
                },
                {
                    icon: '',
                    text: 'Dictionary',
                    link: '#',
                    items: [
                        {
                            text: 'Dictionary',
                            link: '/dictionary/'
                        },
                        {
                            text: 'Dictionary Excel Uploading',
                            link: '/Dictionary-Excel-Page/'
                        }
                    ]
                }

                // {
                //     text: 'Add Article',
                //     link: '/add-article/'
                // },
                // {
                //     text: 'Article List',
                //     link: '/articles-list/'
                // }
            ]
        },
        {
            icon: '',
            text: 'Suggestions',
            link: '#',
            items: [
                {
                    text: 'ADD Suggestions',
                    link: '/add-suggestions/'
                },
                {
                    text: 'Pending Treatments suggestions',
                    link: '/treatments-suggestions/'
                },

                {
                    text: 'Treatments Suggestions Approved',
                    link: '/treatments-suggestions-approved/'
                },
                /*  {
                    text: 'Indications Suggestions',
                    link: '/indications-suggestions-page/'
                },
                {
                    text: 'Indications Suggestions Approved',
                    link: '/indication-suggestions-approved/'
                }, */
                {
                    text: 'Preperations Suggestions',
                    link: '/preparations-suggestions/'
                },
                {
                    text: 'Preperations Suggestions Approved',
                    link: '/preparations-suggestions-approved/'
                }
            ]
        },
        {
            icon: 'fa fa-user m-0',
            text: '',
            link: '',
            items: [
                {
                    text: 'Change password',
                    link: '/change-password/'
                },
                {
                    icon: 'fa fa-lock',
                    event: 'logout',
                    text: 'Logout',
                    link: '/logout/'
                }
            ]
        }

    ]
    ;

    const adminMenu = [
        // {
        //     text: 'Admin Master Data',
        //     link: '#',
        //     items: [
        //         {
        //             text: 'Master Data',
        //             link: '/app/master-data/'
        //         },
        //         {
        //             text: 'Master Data',
        //             link: '/app/master-data/'
        //         }
        //     ]
        // }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
